<template>
    <div class="AgentCashOut">
        <van-empty description="暂无提现记录，努力哦～～" v-if="cashlist.length <= 0">
            <van-button round type="danger" class="bottom-button" to="/agent">
                开始赚钱
            </van-button>
        </van-empty>

        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" v-else>
            <van-cell-group>
                <van-cell v-for="(item, index) in cashlist" :key="index" :title="`¥` + item.money">
                    <template #label>
                        <div style="white-space:nowrap">在 {{ item.created_at }} 申请提现:¥{{ item.money }}</div>
                        <div v-if="item.pay_price > 1">有效提现：¥{{ item.pay_price }}</div>
                    </template>
                    <template #default>
                        <van-tag plain type="primary" v-if="item.status === 1">待审核</van-tag>
                        <van-tag plain type="success" v-else-if="item.status === 2">待到账</van-tag>
                        <van-tag plain type="danger" v-else-if="item.status === 3">提现失败</van-tag>
                        <van-tag plain type="warning" v-else-if="item.status === 4">提现成功</van-tag>
                        <van-tag plain type="danger" v-else>取消</van-tag>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-list>


        <van-cell-group>
            <van-cell title="提现说明">
                <template #label>
                    <div class="tixianshuom">
                        <div>1.到账时间：提交申请后，预计收入将在3个工作日内到账；</div>
                        <div>2.到账通知：平台会向提现账户发送服务提醒通知；</div>
                        <div>3.实名认证：为了确保您的提现安全，需要您进行实名认证，您所认证的信息平台将对其进行隐私加密处理；</div>
                        <div>4.特别说明：我们不会添加您个人微信/支付宝账号，或者要求您进行转账验证等可能泄露您个人信息操作</div>
                        <div>5.提现限制：微信/支付宝平台限制，每天最多只能3次操作提现，全天提现总金额不能超过5000</div>
                        <div>6.手续费说明：微信/支付宝平台限制，每笔交易须收取0.06%的手续费</div>
                    </div>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
export default {
    name: "AgentMyCashOut",
    data() {
        return {
            cashlist: [],
            page:1,
            loading:false,
            finished:false,
        }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            axios.get(
                "withdraw",{
                    params:{
                        page:this.page
                    }
                }
            ).then((result) => {
                if(result.code == 1){
                    if(result.data.data.length == 0){
                        this.finished = true
                        this.over = true
                        return 
                    }
                    this.loading = false
                    this.page++;
                    this.cashlist.push(...result.data.data)
                }else{
                      this.$toast(result.message);
                }
                console.log(result)   
            })
        },
        onLoad() {
            this.getData();
        },
    }
};
</script>

<style scoped>
.tixianshuom > div {
    font-size: 12px;
    color: #ccc;
}
.bottom-button {
    padding: 20px;
}
</style>
